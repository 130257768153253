<template>
    <div id="top" style="background: #fff">
        <div class="fixed-top">
            <div id="top-header" class="top-header">
                <div class="haddress">
                <span><i class="fa fa-map-marker" aria-hidden="true"></i> Drive 2 Prince and Princess Estate, Abuja</span>
                <span><i class="fa fa-phone"></i> 08138184440</span>
                <span><i class="fa fa-envelope"></i> support@dailychamp.ng </span>
                </div>
                <div class="htime" >
                    <span></span>
                    <span><i class="fa fa-clock" aria-hidden="true"></i> Office Hours: 08:00am - 05:00pm</span>
                    <span></span>
                </div>
                <div class="hsocial">
                <span><i class="fa fa-headphones" aria-hidden="true"></i> 24hr support</span>
                
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 d-flex flex-row justify-space-between align-center logo">
                <div class="col-lg-6 col-sm-6 ">
                    <router-link to="/"><i class="fa fa-arrow-left"></i>Back to home</router-link> 
                </div>
               
                <div class="col-lg-6 col-sm-6 logo">
                    <!-- <i class="fa fa-arrow-left"></i><router-link to="/">Back to home</router-link>  -->
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 banner">
            <div class="overlay"></div>
            <div class="col-0"></div>
            <div id="top" class="col-lg-12 col-sm-12 btn-style">
                <h1>Privacy Policy</h1>
            </div>
            <div class="col-lg-0 col-sm-2 img-style">
            </div>
        </div>
        <div id="core-values" class="col-lg-12 col-sm-12 pa-5 core-values">
           
            <div class="col-lg-1 col-sm-1 guarantee">      
            </div>
            <div class="col-lg-10 col-sm-10 guarantee">      
                
                <h2></h2>
                <p>DAILY CHAMP LIMITED is deeply committed to safeguarding your privacy and understands its importance to you. We urge you to carefully review this Privacy Policy, referred to hereinafter as "this Policy," before submitting any personal or private information to DAILY CHAMP LIMITED.</p>
                <p>This Policy applies to all customers of DAILY CHAMP LIMITED who use its digital products through the Daily Champ mobile app, dailychamp.ng websites, and those who subscribe to its feature devices, computer applications, websites, and other digital products or services that reference this Policy. It outlines how DAILY CHAMP LIMITED manages your personal and private data and reaffirms its dedication to ensuring your privacy. Please note that this Policy may not cover all potential data-processing scenarios. </p>
                <p>DAILY CHAMP LIMITED may issue supplementary policies regarding product- or service-specific data collection and processing, which you are advised to read in conjunction with this Policy to fully comprehend DAILY CHAMP LIMITED's privacy practices for a particular product or service.</p>
                <p>What constitutes personal data? Personal data refers to information that, either alone or when combined with other data, can identify an individual. This information is collected when you utilize products or services provided by DAILY CHAMP LIMITED, and may include your name, date of birth, address, identification number, telephone number, account number, photograph, as well as the location and date of accessing products or services. DAILY CHAMP LIMITED collects and utilizes your personal data solely for the purposes outlined in this Policy. The following are examples of personal data we may collect and how we use it:</p>
                <ol>
                    <li>We may collect various forms of personal data, such as your name, phone number, email address, age, and location.</li>
                    <li><strong>Examples of types of personal data we may collect:</strong></li>
                    <ul>
                        <li><strong>Directly submitted data:</strong> Certain DAILY CHAMP LIMITED services may require you to create an account. In such cases, you may be asked to provide account information, such as your email address, phone number, product details, time of purchase, delivery address, and more. All such information is securely stored.</li>
                        <li><strong>Service usage data:</strong> We may collect system and application data from your devices, including device name, system and application versions, regional and language settings, device model, device identification number (IMEI, ESN, MEID, and SN), geographic location (e.g., the area ID where your device is located), service provider network ID (PLMN), usage patterns, and IP address. Additionally, we may log the times at which you access our services, search query terms, and data stored in cookies on your devices.</li>
                        <li><strong>Third-party data:</strong> We may obtain information about you from publicly and commercially available sources, as permitted by law. Furthermore, we may receive information about you from third-party social networking services, such as when you log in to our mobile app or websites using your Facebook or Twitter account.</li>
                    </ul>
                </ol>
                <p>How does DAILY CHAMP LIMITED utilize your personal data? We may use your personal data to:</p>
                <h2>Purposes of Data Processing by DAILY CHAMP LIMITED:</h2>
                <ol>
                    <li>Fulfill purchase orders  including delivering, activating, or verifying products or services; making changes upon your request; and providing technical support and repair services.</li>
                    <li>Contact you by sending information about products and services that may interest you; inviting you to participate in DAILY CHAMP LIMITED promotions and market surveys; or sending you marketing information with your explicit consent. If you prefer not to receive such information, you can opt-out at any time.</li>
                    <li>Inform you about application or site updates and assist in their installation.</li>
                    <li>Offer you a personalized user experience and personalized content, as well as activate your after-sale services.</li>
                    <li>Conduct internal audits, data analysis, and research to enhance our products and services.</li>
                    <li>Analyze the efficiency of our business operations and measure our market share.</li>
                    <li>Enhance customer communications and ensure the provision of secure, quality services to customers.</li>
                    <li>Troubleshoot errors if you choose to provide us with error details.</li>
                    <li>Synchronize, share, and store data you upload or download, as well as data required for uploads and downloads.</li>
                    <li>Improve our loss prevention and anti-fraud programs.</li>
                </ol>
                <p>How are location-based services managed? </p>
                <p>When accessing certain location-based services, such as requesting device delivery or repair pickup, DAILY CHAMP LIMITED may collect, use, and process your device's precise or approximate location. This is necessary for providing these services. </p>
                <p>DAILY CHAMP LIMITED may collect your device's identification numbers (IMEI, ESN, MEID, and SN), type, model, and real-time location data (from GPS, Wi-Fi, and your service provider's network ID) to enhance its location-based products and services.</p>
                <p>We may request you to specify which of DAILY CHAMP LIMITED's digital products you want location-based services enabled for. You have the option to decline sharing your location data by disabling location-based services on your devices. For detailed instructions on how to disable these services, please consult your network operator.</p>
                <p>How is non-identifiable data collected and used? Non-identifiable data, which cannot identify an individual, is also collected by DAILY CHAMP LIMITED. This includes aggregated statistics, such as the number of mobile app installations, website visits, or usage of other applications and digital products and services.
                    DAILY CHAMP LIMITED collects this data to analyze how people use its mobile app, website, and other applications and digital products, aiming to improve its services and better meet customer needs. 
                </p>
                <p>DAILY CHAMP LIMITED may also, at its discretion, collect, use, process, transfer, or disclose non-identifiable data for other purposes.
                    We strive to maintain the separation of your personal data and non-identifiable data, treating each independently. In cases where personal data is combined with non-identifiable data, it will be treated as personal data.
                </p>
                <p>How does DAILY CHAMP LIMITED utilize cookies and similar technologies?</p>
                <p>Cookies:<br>
                    Cookies enable mobile applications, websites, and other digital products to store data such as user preferences or order contents. They typically contain an identifier, site name, and alphanumeric characters. Most major websites and digital service providers use cookies to enhance user experiences. Cookies allow websites to remember users for a single visit (session cookie) or multiple visits (persistent cookie), storing settings like language and font size. This eliminates the need to reconfigure preferences on each visit. Websites that do not use cookies treat users as new visitors each time they visit, which may result in users being logged out when navigating between pages.
                </p>
                <p>To ensure the proper functionality of our mobile app and website, we occasionally store small data files, known as cookies, on computers or mobile devices. A cookie is a simple text file created by a web server and stored on your computer or mobile device. Only the server that created the cookie can access or read its contents, and each cookie is unique to your web browser or mobile application.</p>
                <p>DAILY CHAMP LIMITED utilizes cookies solely for the purposes outlined in this Policy. You can manage or delete cookies based on your preferences. For more information, visit AboutCookies.org. Most web browsers allow you to clear all stored cookies and configure settings to block them. However, if you choose to do so, you may need to manually adjust your user settings each time you visit our app or websites.</p>
                <p>How does DAILY CHAMP LIMITED disclose your personal data?</p>
                <p>DAILY CHAMP LIMITED does not sell your personal data to third parties. However, your personal data may be disclosed under the following circumstances:</p>
                <h2>Disclosures of Personal Data by DAILY CHAMP LIMITED:</h2>
                <ol>
                    <li>Disclosures with explicit consent: DAILY CHAMP LIMITED may share your personal data with other parties after obtaining your explicit approval.</li>
                    <li>Disclosures to DAILY CHAMP LIMITED affiliates: Your personal data may be shared with DAILY CHAMP LIMITED affiliates, but only data that is necessary will be disclosed.</li>
                    <li>Disclosures to authorized partners ("our Partners"): Some DAILY CHAMP LIMITED services are provided by our Partners, and your personal data may be shared with them to improve customer service and user experience. For example, when you purchase DAILY CHAMP LIMITED products through the mobile app or website, DAILY CHAMP LIMITED may share your personal data with logistics providers for delivery scheduling. Shared personal data may only be used for specified, explicit, and legitimate purposes, and our Partners are not authorized to use it for any other purpose. For payment processing, our Partner is Monnify; please refer to Monnify privacy policy for more information.</li>
                    <li>Disclosures based on legal or reasonable grounds: DAILY CHAMP LIMITED may disclose your personal data if required by law, legal proceedings, litigation, or public and governmental authorities. In some jurisdictions, your personal data may also be disclosed in reorganization, merger, or insolvency and liquidation proceedings. Additionally, your data may be disclosed where there is a reasonable need, such as to enforce terms and conditions and protect customers.</li>
                </ol>
                <p>How to access or modify your personal data?</p>
                <p>According to DAILY CHAMP LIMITED's terms of use, you have the ability to manage your personal data, including your account information, at any time. It is important to ensure that all personal data you submit is accurate. DAILY CHAMP LIMITED will make reasonable efforts to maintain the accuracy, completeness, and currency of your personal data.
                    You have the right to access your personal data, except where prohibited by law. DAILY CHAMP LIMITED will comply with applicable privacy and information laws in different jurisdictions. For security reasons, you may need to submit a written request to access your personal data.
                </p>
                <p>You also have the right to request the deletion or correction of any personal information we hold about you. DAILY CHAMP LIMITED will fulfill such requests upon receiving them in writing. However, we may refuse to process requests that we believe are made in bad faith, are impractical, or are not required by local law.</p>
                <p>How does DAILY CHAMP LIMITED safeguard your personal data?</p>
                <p>DAILY CHAMP LIMITED prioritizes the security of personal data and adheres to industry-standard practices to protect it from unauthorized access, disclosure, use, modification, damage, or loss. We implement various measures to ensure the security of your personal data, such as encryption to maintain data confidentiality, trusted protection mechanisms against malicious attacks, visit control mechanisms to restrict access to authorized personnel, and employee training sessions to promote awareness about the importance of personal data protection.</p>
                <p>We also take steps to only collect relevant personal data and retain it for the necessary period to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law. Despite our best efforts, it's important to note that no security measures are entirely foolproof.</p>
                <p>Third-party providers and their services</p>
                <p>In order to ensure the seamless use of our mobile app or a smooth browsing experience on our website, you may encounter content or web links from third parties that are external to DAILY CHAMP LIMITED and our Partners ("Third Parties"). DAILY CHAMP LIMITED does not exercise control over these Third Parties. You have the choice to access or not access links, content, products, and services offered by Third Parties.</p>
                <p>DAILY CHAMP LIMITED does not govern the privacy and data protection policies of Third Parties, over which we have no control. Prior to providing your personal information to Third Parties, please review their respective privacy protection policies.</p>
                <p>How your personal data is transferred internationally</p>
                <p>DAILY CHAMP LIMITED delivers products and services through resources and servers located globally. This means that your personal data may be transferred to or accessed from jurisdictions outside the country where you use our products or services. These jurisdictions may have different data protection laws, or such laws may not exist. In such instances, DAILY CHAMP LIMITED will ensure that your data receives a level of protection similar to that required by all applicable laws and regulations. For example, DAILY CHAMP LIMITED may request your consent to transfer personal data across borders or implement security measures such as data anonymization before cross-border data transfers occur.</p>
                <p>How this Policy is updated</p>
                <p>DAILY CHAMP LIMITED reserves the right to update or amend this Policy periodically. We will notify you of any changes through various channels. If changes are made to our privacy policy, we will post the updated policy on our mobile app or website, www.dailychamp.ng. Additionally, we may issue a separate notice, such as an electronic notification, to inform you of any changes to our privacy policy.</p>
            </div> 
            <div class="col-lg-1 col-sm-1 guarantee">
            </div> 
           
        </div>
      
        <div class="content-footer1">
            <div class="content-footer" id="contactus">
                <div class="contact-info footer-img">
                    <img src="logo.png" alt="">
                    <p>"We guarantee fast, reliable and <br>secured shopping experience"</p>
                </div>
                <div class="contact-info">
                    <p class="contact-title">contact info</p>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Drive 2 Prince and Princess Estate, Abuja</p>
                    <p><i class="fa fa-phone"></i> 08138184440</p>
                    <p><i class="fa fa-envelope"></i> <a href="mailto:support@dailychamp.ng">support@dailychamp.ng</a> </p>
                </div>
                <div class="contact-info">
                    <p class="services-title">Useful Links</p>
                    <p><a href="https://vendor.dailychamp.ng/login" target="_blank">Vendor Hub</a> </p>
                    <p><router-link to="/terms-and-conditions">Terms & Conditions</router-link></p>
                    <p><router-link to="/privacy-policy">Privacy Policy</router-link></p>
                </div>
                <div class="contact-info">
                    <p class="services-title">Socials</p>
                    <p> <span><a href="https://www.facebook.com/devamplify/" target="_blank"><i class="fa fa-facebook-square"></i> Facebook</a></span> </p>
                    <p> <span><i class="fa fa-twitter-square"></i> Twitter </span></p>
                    <p> <span><i class="fa fa-instagram"></i> Instagram</span></p>
                </div>
            </div>
            <div>
                <p class="copyright">&copy;{{ new Date().getFullYear() }} {{company_name }}. All rights reserved. </p>
            </div>
        </div>  
        <!-- <div class="contact-support">
          <a href="https://wa.me/2347069500520" target="_blank"><img src="../assets/support.png" alt=""></a> 
         
        </div>   -->
        <a href="#top">  
        <div class="top">
            <i class="fa fa-arrow-up"></i>
        </div>  
        </a>   
</div>
</template>
<script>
import swal from 'sweetalert2';
import $ from 'jquery';

export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        company_name: process.env.VUE_APP_COMPANY,
        loading: false,
        fullname: '',
        email_address: '',
        phone_number: '',
        message: '',
        show: false,
        title: 'Daily Champ | Online Shopping Site for Mobiles, Electronics, Clothing, Solar and More. Best Offers',
        items: [
          {
            src: process.env.VUE_APP_API_BANNER,
          },
          {
            src: process.env.VUE_APP_API_BANNER2,
          },       
        ],
        showTop: true,
        lastScrollTop: 0
    };
  },

  mounted() {
    $('html, body').animate({scrollTop: 0}, 800);
    $(document).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.top').fadeIn();
            document.getElementById('top-header').style.display = 'none'
        } else {
            $('.top').fadeOut();
            if ($(this).scrollTop() == 0) {
                document.getElementById('top-header').style.display = 'flex'
            }
        }
    });

    $('.top').click(function() {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
    });
});

    document.title = this.title
   
  },
  beforeDestroy() {
   
  },
    created(){

    },
    computed: {
    isSubmitButtonDisabled() {
        return (
        this.fullname === '' ||
        this.email_address === '' ||
        this.phone_number === '' ||
        this.message === '' 
        );
    }
  },

  methods: {
   
    async submit_form() {
            try {

                this.loading = true

                const response = await this.$http.post('/send-message', {
                  fullname: this.fullname,
                  email_address: this.email_address,
                  phone_number: this.phone_number,
                  message: this.message
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })

                this.fullname = ''
                this.email_address = ''
                this.phone_number = ''
                this.message = ''

                return;
               
            } catch (err) {
              console.log(err);
        }
    },
  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.fixed-top{
    z-index: 9999;
    background: #fff;
}
.top-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    padding-left: 5%;
    padding-right: 5%;
    color: #000000d6;
    background: #8000802b;
    box-shadow: 0 2px 4px -3px rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 12px;
}
.haddress{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.htime{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.hsocial{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
        margin: 10px;
    }

    a{
        color: #000;
    }
}
.core-values{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 200;
        text-align: center;
    }
}
.guarantee{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    font-size: 13px;
    text-align: justify;
    h2{
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
    }
}
.btn-success{
    background: #800080;
    border: solid 0;
    color: #FFF;
    height: 50px;
    border: solid 1px #fff;
}
.btn-success:hover{
    background: #800080ae;
    border: solid 0;
    color: #FFF;
    height: 50px;
}
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 1.1s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
.header {
    background: #F2F2F2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
}
.sidebar{
    display: none;
}
.fa-bars {
    display: none;
}
.menu-bar {
    height:70px;
}

.menu-bar1{
    display:none;
 }
 .submenu-bar1{
    display:none;

    .submenu-bar{
        display: flex;
        flex-direction: column;
        left: 0;
        font-family: Mulish;
        font-size: 13px;
        border-right: solid 1px rgba(0, 0, 0, 0.3);;
        bottom: 0;
        height: 100vh;
    
        a{
            text-decoration: none;
            color: #312F33;
        }
    }
 }

.logo{
    height: 50px;
    a{
        text-decoration: none;
        color: #800080e6;
    }
}

.menu > a {
    color: #800080e6;
    font-weight: 800;
    font-family: Mulish;
}
.menu:hover {
    cursor: pointer;
}
.banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 9.5rem;
    bottom: 0;
    font-family: Mulish;
    font-size: 18px;
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg.jpg) no-repeat fixed center;
    background-size: cover;
      
    h1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: Mulish;
        font-weight: 650;
        font-size: 50px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1px #000; 
    }
    h4{
        font-family: Mulish;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1px #000; 
    }
    .btn-style{
        font-family: Mulish;
        text-align: center;
    }
    .img-style{
        img{
            //width: 100%;
        }
    }
}  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #800080e6; /* Change the color and opacity here */
  }

.bg-style{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(-90deg, #80008014, #80008055);
    
    img{
        width: 110%;
    }
    .platform{
        display:flex;
        flex-direction: column;
        padding: 2.6rem;

    h2{
        font-size: 30px; 
        font-weight: 700;
        font-family: Mulish;
    }
    span{
        font-size: 14px; 
        font-weight: 350;
        font-family: Mulish;
    }
}
}

.bg-task{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: auto;
    h2{
        font-size: 40px; 
        font-weight: 600;
        font-family: Mulish;

    }
    img{
        width: 90%;
    }
    .task-img{
        
    }
}
.select-item{
    border-left: solid 6px #8000808a;
    margin: 4px;
    padding: 0.2rem;
    font-size: 15px; 
    font-weight: 600;
    font-family: Mulish;
}
.slider {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 500px; 
    font-size: 45px; 
    font-weight: bold;
    text-shadow: 0px 1px #000; 
    font-family: Mulish;
    text-align: center;
  }
.sample-product-subtitle{
    font-size: 20px !important; 
    text-shadow: 0px 0px #f5f9f711; 
    text-align: center;
    width: 50%;
    color: #fff;
    font-weight: 300 !important;
  }
.action-botton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.action-botton2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.content-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px;
    font-family: Mulish;
    font-size:17px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    span{
        font-size:20px;
        color: #800080e6;
        font-weight: bold;
    }
  }
  .content-services{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff !important;
  }
  .content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .content-items{
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 10px;
    padding: 15px;
    text-align: center;
  
    p{
      font-family: Mulish;
      font-size:20px;
      font-weight: 700;

      .fa-handshake-o{
        font-size:50px;
        color: #800080e6;
      }
      .fa-wallet{
        font-size:50px;
        color: #800080e6;
      }
      .fa-graduation-cap{
        font-size:50px;
        color: #800080e6;
      }
      .fa-leaf{
        font-size:50px;
        color: #800080e6;
      }
    }
  
    .items-text {
      font-family: Mulish;
      font-size:14px;
      font-weight: 500;
      text-align: center;
    }
  
  }
  .about{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background: #33aacb14;
    background-size: cover;
    margin-top: 0px;
    height: auto;

    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: bold;
        text-align: justify;
    }
    p{
        font-family: Mulish;
        font-size: 13px;
    }
  }
  .team{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    
    h4{
        margin-top: 15px;
    }

    .name{
        font-family: Mulish;
        font-size: 13px;
        font-weight: bold;
    }
    i{
        margin-top: -15px;
    }
  }
  .border{
    border-left: solid 1px #ccc;
    padding: 1.5rem;
  }
  .bd{
    padding: 1.5rem;
  }
  .about-banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    width: 100%;
    padding: 0.6rem;
  }
  .img-banner{
   
    img{
        width: 90%;
    }
  }

  .contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 11px;
    background: #33aacb14;
    background-size: cover;
    margin-top: 0px;
    height: auto;

    .support-text{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        text-align: justify;
    }
    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: 500;
        text-align: justify;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 550;
        color: #3ea4c9c9;
    }
    img{
        width: 100%;
    }
  }
  .border{
    border-left: solid 1px #ccc;
    padding: 1.5rem;
  }
  .bd{
    padding: 1.5rem;
  }
  .contact-banner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    font-family: Mulish;
    padding: 1.6rem;

    .form-control{
        font-size: 12px;
        font-family: Mulish;
    }
    .input-contact{
        margin-top: 4px;
        color: #fff;
    }
  }


  .text-banner{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Mulish;
    width: 50%;
    p{
        text-align: justify;
    }

    .our-vision{
        margin: 4px;
        font-family: Mulish;
        font-size: 13px;

        .company{
            font-weight: bold;
        }
        .mission{
            font-weight: bold;
        }
        .vision{
            font-weight: bold;
        }
        .impact{
            font-weight: bold;
        }
    }
  }


   .content-footer1 {
    display: flex;
    flex-direction: column;
    color: #fff;
    bottom: 0;
    background: #800080e6; 
  }

  .content-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 2.7rem;
    padding-left: 5%;
    padding-right: 5%;
  }
  .footer-img{
    display: flex;
    flex-direction: column;
    text-align: left
  }
  .content-footer-text{
    font-family: Mulish;
    font-size:12px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  
  .contact-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img{ width:20% }

    p{
        font-family: Mulish;
        font-size:12px;    
    }
    a{
        text-decoration: none;
        color: #fff;
    }
  }
  .contact-title{
    font-size: 18px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .services-title{
    font-size: 16px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .copyright{
    font-family: Mulish;
    font-size:13px;
    font-weight: 400;
    text-align: left;
    padding-left: 5%;
  }
  
  .input-control {
    height: 30px;
    font-size: 12px;
    font-family: Mulish;
}
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #37ABC8;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}
.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #FFF;
    }

    display: none;
}
.top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    display: block;
    z-index: 9999;
    background: #800080;
    color: #fff;
    border-radius: 45px;
    padding: 10px;
    transition: visibility 0.5s, opacity 0.5s;

    a{
        color: #fff;
    }
}
.hidden {
    visibility: hidden;
    opacity: 0;
}

.contact-support{
    position: fixed;
    bottom: 20px;
    right: 80px;
    z-index: 9999;
    background: #ccc;
    color: #fff;
    border-radius: 12px;
    transition: visibility 0.5s, opacity 0.5s;

    img{
        width: 60px;
    }
}
@media (min-width:320px) and (max-width: 720px) {

.top-header{
    font-size: 10px;
}
.core-values{
    display: flex;
    flex-direction: column;

    h3{
        font-family: Mulish;
        font-size: 15px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
        margin-top: 10px;
    }
    span{
        font-family: Mulish;
        font-size: 11px;
        font-weight: 200;
        text-align: center;
    }
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu-bar {
    display: flex; 
    flex-direction: column; 
    height:70px;
}

.menu-bar1{
    display:block;
    .fa-bars{
        font-size: 22px;
        cursor: pointer;
    }
 }
 .menu-bar1:hover{
    
        .submenu-bar1{
            display: flex;
            flex-direction: column;
            right: 0;
            font-family: Mulish;
            font-size: 13px;
            border-right: solid 1px rgba(0, 0, 0, 0.3);;
            background: #800080;
            position: fixed;
            z-index: 9998;
            //border-top-right-radius: 4px;
            //border-bottom-right-radius: 4px;
                        
            a{
                text-decoration: none;
                color: #fff;
            }
        }
        .sub-menu1{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            border-left: solid 1px #ccc;
            border-bottom: solid 1px #ccc;
            text-transform: capitalize;
            cursor: pointer;
            padding: 0.8rem;
            width: 100%;
        }
 }
 .sub-menu1:hover{
    background: #fff;
    color: #800080 !important;
 }

.guarantee{
    div{
        font-family: Mulish;
        font-size: 14px;
        font-weight: 450;
        color: #00000096;
        text-align: center;
    }
}

.bg-task{
    display: flex;
    flex-direction: column;
    height: auto;
    h2{
        font-size: 30px; 
        font-weight: 600;
        font-family: Mulish;

    }
    p{
        font-size: 12px; 
        font-weight: 400;
        font-family: Mulish;

    }
    img{
        width: 90% !important;
    }
    .btn{
        margin-bottom: 10px;
    }
    .task-img{
        display: none;
    }
}
.bg-style{
    display:flex;
    flex-direction: column;
    background-image: linear-gradient(-90deg, #80008014, #8000806b);

    img{
        width: 100%;
    }
    .platform{
        display:flex;
        flex-direction: column;
        width: 100%;
        padding: 0.6rem;

        h2{
            font-size: 30px; 
            font-weight: 100;
            font-family: Mulish;
        }
        span{
            font-size: 14px; 
            font-weight: 350;
            font-family: Mulish;
        }
    }
}

#dailytask{
    display: flex;
    flex-direction: column;  
    justify-content: center; 
    align-content: center;
    width: 100%;

    h2{
        font-family: Mulish;
        margin-top: -40px;
        z-index: 9666;
        color: #000000c0;
    }

    p{
        font-family: Mulish;
        font-size: 12px;
    }
    img{
        width: 120%;
    }
}

#dailytask .d-flex .flex-column .pt-0 .mt-0 {
    display: flex;
    flex-direction: column;
  
}


   
    .sidebar {
        width: auto;
        height: auto;
        position: fixed;
        z-index: 9998;
        transition: opacity .4s ease;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0px;
        margin-left: 10px;

        a{
            color: #37ABC8;
        }
        .close{
            margin-left: 20px;
        }
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}

   .logo{
     img{ width: 150%; }
    }
    .fa-bars {
        display: block;
        font-size: 30px;
        cursor: pointer;
        color: #800080e6;
    }
    .menu {
        display: none;
    }
    .banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 9.5rem;
        bottom: 0;
        font-family: Mulish;
        font-size: 18px;
        background:#800080e6;
        padding-top: 30px;
        overflow: hidden;
        position: relative;
        backdrop-filter: blur(16px);
        background: url(../assets/bg.jpg) no-repeat fixed center;
        background-size: cover;
        
        h1{
            font-family: Mulish;
            font-weight: 700;
            font-size: 30px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px #000; 
        }
        h4{
            font-family: Mulish;
            font-weight: 400;
            font-size: 15px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px #000; 
        }
        .btn-style{
            font-family: Mulish;
            text-align: center;
        }
        .img-style{
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                text-align: center;
                width: 250px;
            }
        }
    }
    .action-botton{
        margin-top: 50px;
    }
    .action-botton2{
        margin-top: 50px;
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
      }
      .content-items{
        box-sizing: border-box;
        width: 100%;
      }
      
      .about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        margin-bottom: 50px;
        box-shadow: -1px -1px 4px #ccc;
        height: auto;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: bold;
            text-align: justify;
        }
        p{
            font-family: Mulish;
            font-size: 13px;
        }
      }
      .team{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        
        h4{
            margin-top: 15px;
        }
    
        .name{
            font-family: Mulish;
            font-size: 13px;
            font-weight: bold;
        }
        i{
            margin-top: -15px;
        }
      }
      .border{
        border-left: solid 1px #ccc;
        padding: 1.5rem;
      }
      .bd{
        padding: 1.5rem;
      }
      .about-banner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        width: 100%;
        padding: 0.6rem;
      }
      .img-banner{
       
        img{
            width: 100%;
        }
      }

      .contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 11px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        height: auto;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: 500;
            text-align: justify;
        }
        span{
            font-family: Mulish;
            font-size: 13px;
            font-weight: 800;
        }
        img{
            width: 100%;
        }
      }

      .content-footer {
        display: flex;
        flex-direction: column;
        padding-top: 2.7rem;
        bottom: 0;   
        background: #8000802b; 
        padding-left: 5%;
        padding-right: 5%;
      }
      .content-footer-text{
        font-family: Mulish;
        font-size:13px;
        font-weight: 500;
        text-align: center;
        word-wrap: break-word;
        width: 28em; 
        color: #000;
      }
      .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
      
        p{
          font-size: 20px; 
        }
      }

    
      .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 241, 241, 0.5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #37ABC8;
            background: #f4f7f5;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .contact-info{
        display: flex;
        flex-direction: column;
    
        img{ width:20% }
    
        p{
            font-family: Mulish;
            font-size:13px;    
        }
      }
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: none;
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: block;
    }

}
</style>