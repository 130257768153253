import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "../views/HomePage.vue"
import TermsConditions from "../views/TermsConditions.vue"
import PrivacyPolicy from "../views/PrivacyPolicy.vue"

Vue.use(VueRouter);
// parse jwt token

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
