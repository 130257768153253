<template>
    <div id="top" style="background: #fff">
        <div class="fixed-top">
            <div id="top-header" class="top-header">
                <div class="haddress">
                <span><i class="fa fa-map-marker" aria-hidden="true"></i> Drive 2 Prince and Princess Estate, Abuja</span>
                <span><i class="fa fa-phone"></i> 08138184440</span>
                <span><i class="fa fa-envelope"></i> <a href="mailto:support@dailychamp.ng">support@dailychamp.ng</a> </span>
                </div>
                <div class="htime" >
                    <span></span>
                    <span><i class="fa fa-clock" aria-hidden="true"></i> Office Hours: 08:00am - 05:00pm</span>
                    <span></span>
                </div>
                <div class="hsocial">
                <span><i class="fa fa-headphones" aria-hidden="true"></i> 24hr support</span>
                
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 d-flex flex-row justify-space-between align-center logo">
                <div class="col-lg-6 col-sm-6 ">
                    <router-link to="/"><i class="fa fa-arrow-left"></i>Back to home</router-link> 
                </div>
               
                <div class="col-lg-6 col-sm-6 logo">
                    <!-- <i class="fa fa-arrow-left"></i><router-link to="/">Back to home</router-link>  -->
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 banner">
            <div class="overlay"></div>
            <div class="col-0"></div>
            <div id="top" class="col-lg-12 col-sm-12 btn-style">
                <h1>Terms and Conditions</h1>
            </div>
            <div class="col-lg-0 col-sm-2 img-style">
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 pa-5 core-values">
            <div class="col-lg-1 col-sm-1 guarantee">      
            </div>
            <div class="col-lg-10 col-sm-10 guarantee">      
                <ol>
                    <p>&nbsp;</p>
                    <h2>Terms of Purchase</h2>
                    <li>Items in Daily Champ can be purchased on installment, but buyers who prefer to buy outrightly can select the pay-once option. Buyers can change their chosen installment plan anytime before the item's delivery is processed.</li>
                    <li>All purchase orders are subject to approval by Daily Champ before delivery.</li>
                    <li>The Installment Plan allows buyers to pay for their orders in equal consecutive installments over a desired number of weeks or months (e.g., 4 weeks, 2 months, 6 months, etc.). Once a payment duration is chosen, it must be completed, and it cannot be altered once the item(s) are processed for delivery. Buyers can request to change their installment plan before reaching 50% of the chosen installment plan or before delivery is processed. For example, a buyer who chooses a 6-month payment plan can only request delivery of the product(s) after paying 50% of the purchase price and when due according to the chosen installment plan.
                        <ol type="i">
                            <li>A buyer who pays 50% of the purchase price before the midpoint of the chosen payment duration shall ONLY be entitled to request delivery. Also, a buyer who makes a payment up to the midpoint of the chosen installment can request delivery.</li>
                        </ol>
                    </li>
                    <li>A buyer who wishes to change their chosen installment duration or plan for payment should contact Daily Champ through the provided means for that purpose.
                        <ol type="i">
                            <li>The buyer must await approval from Daily Champ indicating whether the proposed new duration is acceptable. If the proposed payment duration is acceptable, the buyer's payment plan will be adjusted accordingly. Until the buyer receives approval from Daily Champ that the change in duration has been accepted, the initially chosen payment duration will remain in effect.</li>
                            <li>Once the change in installment plan/payment duration is approved by Daily Champ, the buyer must immediately pay any amount due according to the approved plan.</li>
                            <li>Daily Champ reserves the right to approve or deny a buyer's request to change their initial chosen installment plan/payment duration.</li>
                            <li>Buyers are responsible for paying for the delivery of ordered items before the delivery process is initiated.</li>
                        </ol>
                    </li>
                    <li>Installment plans do not incur processing fees, additional costs, or hidden charges. However, processing fees charged by payment gateway service providers apply, as well as the calculated delivery fee for the ordered items, all of which must be paid by the buyers before the ordered items are processed for delivery.</li>
                    <li>To enroll in an Installment Plan on Daily Champ, a buyer must:
                        <ol type="i">
                            <li>Register (or sign in if already registered) on Daily Champ.</li>
                            <li>Once logged in, all available items are listed in the marketplace.</li>
                            <li>Browse through the listed items and select desired ones.</li>
                            <li>Follow the prompts to choose an installment plan and provide other required details, then proceed to start the installment.</li>
                        </ol>
                    </li>
                    <li>Failure to pay an installment sum by the due date(s) allows Daily Champ to terminate an active installment plan. In such cases, the amount already paid will be refunded, minus 10% of the total installment sum already paid, including the initial deposit if any. No refund will be issued earlier than 30 days from the termination date of the installment plan. If no termination notice is communicated to the buyer(s) and they continue to make payments, Daily Champ reserves the right to defer/delay delivery of the ordered items until the purchase price is fully paid by the buyer(s).</li>
                    <li>Buyers who fail to consistently pay the installment sum as and when due will not be entitled to the delivery of ordered items until the full purchase price is paid in full. A buyer who consistently fails to make payments can only request delivery after paying the last installment sum. Daily Champ reserves the right not to honor any delivery request made by such buyers before paying the last installment and to delay the delivery of the ordered items until the last installment has been paid.</li>
                    <li>Buyers may make payments at any time before the due date. If a buyer fails to process an installment payment by the due date and an automatically attempted payment fails, the following actions will occur:
                        <ol type="i">
                            <li>If the ordered items have not been delivered, the provisions of paragraph 8 above will apply.</li>
                            <li>If the ordered items have been delivered, the following late fee charges will apply:
                                <ol>
                                    <li>For weekly installments, a late fee of 5% of the amount due will be charged on a weekly basis until payment is made by the buyer.</li>
                                    <li>For monthly installments, a late fee of 13% of the amount due will be charged on a monthly basis until payment is made by the buyer.</li>
                                    <li>There is no limit to the late fee payable if the ordered items have been delivered.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>Buyers are eligible to have ordered items delivered 15 days after the midpoint of the chosen installment plan, provided that 50% of the item's price has been paid. However, a buyer will only be eligible for delivery if they have consistently paid the installment sum as and when due. Failure to meet this requirement will postpone the delivery of ordered items until the installment is fully completed. Despite the delivery of ordered items to the buyer, the following terms will apply at all times during an active installment plan:
                        <ol type="i">
                            <li>All items purchased on an installment plan remain the property of Daily Champ until buyers complete all installment payments. Daily Champ's ownership of all ordered items continues even after the items are delivered to the buyers, and this ownership continues until the buyers fully complete the installment payment.</li>
                            <li>For all intents and purposes, buyers are considered lessees of the delivered items with the right to purchase them. The installment sum paid is considered rent payable for the use of the items until the full purchase price is paid.</li>
                            <li>A buyer who fails to complete the installment payment as and when due loses the right to purchase the affected items and must immediately return them if already delivered.</li>
                            <li>Upon becoming obligated to return the items, Daily Champ reserves the right to retrieve them from the buyer's possession without obtaining the buyer's consent.</li>
                            <li>Daily Champ is entitled to retrieve the items without recourse to court or obtaining any administrative order.</li>
                            <li>All costs incurred in retrieving items from the buyer must be paid in full by the buyer before the items are returned or refunds are made.</li>
                            <li></li>
                        </ol>
                    </li>
                   
                    <li>
                        Daily Champ may demand the return of delivered items:
                        <ol type="i">
                            <li>Virtually by sending emails, SMS, or placing phone calls to the buyers, or</li>
                            <li>Physically by sending Daily Champ Recovery Agents to visit the buyers' designated addresses or locations to demand the return of the delivered items.</li>
                            <li> Upon demand by Daily Champ, defaulting buyers must promptly return all affected items to Daily Champ's designated office within 48 hours of the demand by email or phone call. Alternatively, buyers must immediately deliver the affected items to Daily Champ Recovery Agents upon their visit to the buyers' designated addresses for such recovery. A buyer who promptly returns or delivers the affected items is entitled to a refund in line with paragraphs 14e (i) to 14(iv) below. A buyer who fails to promptly return or deliver the affected items as stated above may be charged with criminal conversion and is liable to pay the cost of litigation as well as attorney's fees for themselves and for Daily Champ. This is in addition to all other rights that Daily Champ may exercise against the defaulting buyer.</li>
                        </ol>

                       
                    </li>
                    <li>
                        Delivery of items purchased on an installment plan is subject to the availability of the ordered items at the midpoint of the chosen installment plan when at least 50% of the purchase price must have been paid (e.g., the third month of a six-month installment plan), not the availability on the date the items were ordered. If the ordered items are no longer available at the midpoint of the chosen installment plan when at least 50% of the sales price of the ordered items must have been paid, buyers will be asked to choose other items within the same range or category from a list of items in the Daily Champ marketplace. Alternatively, buyers may be asked to wait for a certain period during which Daily Champ will make reasonable efforts to procure the ordered items for delivery to the buyers in line with Daily Champ's delivery policies. The price payable for items purchased on an installment plan will be the current price listed on Daily Champ at the midpoint of the installment plan duration when delivery is being processed, not the price at the date the order was made by the buyers. Daily Champ reserves the right to review the purchase price of any ordered items either upward or downward. However, a downward review of the purchase price is only possible before the buyers reach the midpoint of their chosen installment plan, not thereafter. In the event of an upward review of the purchase price, buyers will be notified to accept the difference in price before the items are processed for delivery. Please note that the upward or downward review of a purchase price is entirely at Daily Champ's discretion, and Daily Champ will only be obligated to deliver ordered items whose purchase price has been reviewed if the buyers agree to pay the difference in the reviewed price and fulfill all other terms and conditions of Daily Champ.
                    </li>
                    <li>
                        Buyers may request to terminate the installment plan at any time before the ordered items are delivered. Refunds of any payments made prior to the termination date are refundable, less an amount equal to 10% of the total installment sum already paid, including the initial deposit if any. No refund will be issued earlier than 30 days from the termination date of the installment plan.
                    </li>
                    <li>
                        Buyers must ensure that each payment is made on or before its due date to successfully complete the installment plan and receive the ordered items according to the terms and conditions herein contained. Failure to make any payment as and when due will automatically terminate the installment plan without any recourse to the buyer whose installment plan was terminated. Refunds of any installment payments made prior to the termination date are refundable, less an amount equal to 10% of the total installment sum already paid, including the initial deposit. Failure to make any payment by its due date will result in their eligibility to subscribe to an installment plan being queried, and such buyers will no longer be able to subscribe to any other installment plan unless they provide sufficient reasons to Daily Champ and are subsequently cleared by a Daily Champ agent after consenting to be charged according to the terms and conditions that may be communicated to the buyers at the relevant time. For all intents and purposes, the ordered items shall not be delivered to defaulting buyers until the installment payment is fully paid. In the event of a refund, no refund shall be paid earlier than 30 days from the date of termination of the installment payment, whether termination was due to the buyer's request or the buyer's failure to comply with the term of the installment plan prior to the delivery of items. Failure to comply with the terms of the installment payment plan after the ordered items have been delivered to buyers shall be treated as follows:
                        <ol type="i">
                            <li>
                                The items delivered will be reclaimed from the buyer according to the guidelines outlined in Section 10, Subsections 1 to 9. These items will be resold as used items, and Daily Champ is not obligated to exercise any duty of care in their sale. The unpaid balance of the installment sum will be deducted from the sale amount of the items. All costs incurred in retrieving and selling the items will be deducted from the balance of the sale amount, with the remaining balance refunded to the buyer. However, no refund will be issued if the sales price of the used items is fully depleted after all deductions. Refunds, if applicable, will not be processed until 30 days after the retrieved items are sold as used items. Daily Champ reserves the right to withhold delivery of an item until 100% payment is received if a buyer has defaulted on previous payments. Additionally, Daily Champ may choose not to deliver an item until 100% payment is received on suspicion of fraud, without disclosing the reason to the customer.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Daily Champ is not required to participate in promotional sales or seasonal discounts, nor is it obligated to offer clearance sales, discounts, bonuses, or promotions for any items listed on its platform. However, Daily Champ may choose to run promotions at its discretion.
                    </li>
                    <li>
                        By subscribing to any purchase plan on Daily Champ, the buyer (subscriber) accepts the platform's terms and conditions, representing eligibility for the installment plan. Subscription to the installment plan services is void if the subscriber is found to be ineligible.
                    </li>
                    <li>
                        Champ Pocket serves as a temporary repository for funds and is not intended for saving money, nor is Daily Champ functioning as a bank. Funds deposited into Champ Pocket are automatically considered installment payments. Refunds from Champ Pocket will incur a 10% withdrawal fee and will only be processed after the user's identity is verified.
                    </li>
                </ol>
                <h2>Eligibility Criteria</h2>
    <ol>
        <li>To qualify for the Installment Plan subscription, a buyer must:</li>
        <ul>
            <li>Be a registered user of Daily Champ.</li>
            <li>Be 18 years old or older.</li>
            <li>Be a Nigerian citizen and resident.</li>
            <li>Be capable of entering into a legally binding contract.</li>
            <li>Have a valid and verifiable email address and mobile phone number.</li>
            <li>Have duly registered and can electronically verify any of the following:</li>
            <ul>
                <li>National Identification Card showing the National Identification Number (NIN).</li>
                <li>Bank Verification Number (BVN).</li>
            </ul>
            <li>Provide a valid delivery address in Nigeria.</li>
            <li>Be an authorized holder of an eligible Naira debit/MasterCard or any other valid payment card, with the authority to use the provided payment method.</li>
            <li>Be willing to add the payment card details to their account for payment automation.</li>
            <li>Live or work at an electronically verifiable address.</li>
        </ul>
    </ol>
    <h2>Insurance Terms</h2>
    <ul>
        <li>Insurance on Daily Champ is optional and costs 10% of the item's price.</li>
        <li>Insurance does not cover theft; the item must be physically provided and identifiable with a serial number or equivalent for claims.</li>
        <li>Insurance only covers repair or replacement of damage not covered by the manufacturer's warranty.</li>
        <li>Customers must pay 20% of the repair or replacement cost.</li>
        <li>To claim insurance, the customer must complete the item's payment and pay 20% of the estimated repair or replacement cost.</li>
        <li>The customer claiming insurance bears the cost of logistics.</li>
    </ul>

    <h2>Disclaimer</h2>
    <p>
        Daily Champ is not involved in the manufacturing of any product listed on the platform. Therefore, Daily Champ is not liable for any errors, defects, or damages suffered by buyers resulting from the use of any purchased items. The descriptions of items on Daily Champ are provided by the respective manufacturers, who are liable for any issues in line with their terms and conditions.
    </p>               
                
               
            </div> 
            <div class="col-lg-1 col-sm-1 guarantee">
            </div> 
           
        </div>
      
        <div class="content-footer1">
            <div class="content-footer" id="contactus">
                <div class="contact-info footer-img">
                    <img src="logo.png" alt="">
                    <p>"We guarantee fast, reliable and <br>secured shopping experience"</p>
                </div>
                <div class="contact-info">
                    <p class="contact-title">contact info</p>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Drive 2 Prince and Princess Estate, Abuja</p>
                    <p><i class="fa fa-phone"></i> 08138184440</p>
                    <p><i class="fa fa-envelope"></i> <a href="mailto:support@dailychamp.ng">support@dailychamp.ng</a> </p>
                </div>
                <div class="contact-info">
                    <p class="services-title">Useful Links</p>
                    <p><a href="https://vendor.dailychamp.ng/login" target="_blank">Vendor Hub</a> </p>
                    <p><router-link to="/terms-and-conditions">Terms & Conditions</router-link></p>
                    <p><router-link to="/privacy-policy">Privacy Policy</router-link></p>
                </div>
                <div class="contact-info">
                    <p class="services-title">Socials</p>
                    <p> <span><a href="https://www.facebook.com/devamplify/" target="_blank"><i class="fa fa-facebook-square"></i> Facebook</a></span> </p>
                    <p> <span><i class="fa fa-twitter-square"></i> Twitter </span></p>
                    <p> <span><i class="fa fa-instagram"></i> Instagram</span></p>
                </div>
            </div>
            <div>
                <p class="copyright">&copy;{{ new Date().getFullYear() }} {{company_name }}. All rights reserved. </p>
            </div>
        </div>  
        <!-- <div class="contact-support">
          <a href="https://wa.me/2347069500520" target="_blank"><img src="../assets/support.png" alt=""></a> 
         
        </div>   -->
        <a href="#top">  
        <div class="top">
            <i class="fa fa-arrow-up"></i>
        </div>  
        </a>   
</div>
</template>
<script>
import swal from 'sweetalert2';
import $ from 'jquery';

export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        company_name: process.env.VUE_APP_COMPANY,
        loading: false,
        fullname: '',
        email_address: '',
        phone_number: '',
        message: '',
        show: false,
        title: 'Daily Champ | Online Shopping Site for Mobiles, Electronics, Clothing, Solar and More. Best Offers',
        items: [
          {
            src: process.env.VUE_APP_API_BANNER,
          },
          {
            src: process.env.VUE_APP_API_BANNER2,
          },       
        ],
        showTop: true,
        lastScrollTop: 0
    };
  },

  mounted() {
    $('html, body').animate({scrollTop: 0}, 800);
    $(document).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.top').fadeIn();
            document.getElementById('top-header').style.display = 'none'
        } else {
            $('.top').fadeOut();
            if ($(this).scrollTop() == 0) {
                document.getElementById('top-header').style.display = 'flex'
            }
        }
    });

    $('.top').click(function() {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
    });
});

    document.title = this.title
   
  },
  beforeDestroy() {
   
  },
    created(){

    },
    computed: {
    isSubmitButtonDisabled() {
        return (
        this.fullname === '' ||
        this.email_address === '' ||
        this.phone_number === '' ||
        this.message === '' 
        );
    }
  },

  methods: {
   
    async submit_form() {
            try {

                this.loading = true

                const response = await this.$http.post('/send-message', {
                  fullname: this.fullname,
                  email_address: this.email_address,
                  phone_number: this.phone_number,
                  message: this.message
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })

                this.fullname = ''
                this.email_address = ''
                this.phone_number = ''
                this.message = ''

                return;
               
            } catch (err) {
              console.log(err);
        }
    },
  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.fixed-top{
    z-index: 9999;
    background: #fff;
}
.top-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    padding-left: 5%;
    padding-right: 5%;
    color: #000000d6;
    background: #8000802b;
    box-shadow: 0 2px 4px -3px rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 12px;
}
.haddress{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.htime{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.hsocial{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
        margin: 10px;
    }

    a{
        color: #000;
    }
}
.core-values{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 200;
        text-align: center;
    }
}
.guarantee{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    font-size: 13px;
    text-align: justify;
    h2{
        font-family: Mulish;
        font-size: 18px;
        font-weight: 500;
    }
}
.btn-success{
    background: #800080;
    border: solid 0;
    color: #FFF;
    height: 50px;
    border: solid 1px #fff;
}
.btn-success:hover{
    background: #800080ae;
    border: solid 0;
    color: #FFF;
    height: 50px;
}
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 1.1s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
.header {
    background: #F2F2F2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
}
.sidebar{
    display: none;
}
.fa-bars {
    display: none;
}
.menu-bar {
    height:70px;
}

.menu-bar1{
    display:none;
 }
 .submenu-bar1{
    display:none;

    .submenu-bar{
        display: flex;
        flex-direction: column;
        left: 0;
        font-family: Mulish;
        font-size: 13px;
        border-right: solid 1px rgba(0, 0, 0, 0.3);;
        bottom: 0;
        height: 100vh;
    
        a{
            text-decoration: none;
            color: #312F33;
        }
    }
 }

.logo{
    height: 50px;
    a{
        text-decoration: none;
        color: #800080e6;
    }
}

.menu > a {
    color: #800080e6;
    font-weight: 800;
    font-family: Mulish;
}
.menu:hover {
    cursor: pointer;
}
.banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 9.5rem;
    bottom: 0;
    font-family: Mulish;
    font-size: 18px;
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg.jpg) no-repeat fixed center;
    background-size: cover;
      
    h1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: Mulish;
        font-weight: 650;
        font-size: 50px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1px #000; 
    }
    h4{
        font-family: Mulish;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1px #000; 
    }
    .btn-style{
        font-family: Mulish;
        text-align: center;
    }
    .img-style{
        img{
            //width: 100%;
        }
    }
}  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #800080e6; /* Change the color and opacity here */
  }

.bg-style{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(-90deg, #80008014, #80008055);
    
    img{
        width: 110%;
    }
    .platform{
        display:flex;
        flex-direction: column;
        padding: 2.6rem;

    h2{
        font-size: 30px; 
        font-weight: 700;
        font-family: Mulish;
    }
    span{
        font-size: 14px; 
        font-weight: 350;
        font-family: Mulish;
    }
}
}

.bg-task{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: auto;
    h2{
        font-size: 40px; 
        font-weight: 600;
        font-family: Mulish;

    }
    img{
        width: 90%;
    }
    .task-img{
        
    }
}
.select-item{
    border-left: solid 6px #8000808a;
    margin: 4px;
    padding: 0.2rem;
    font-size: 15px; 
    font-weight: 600;
    font-family: Mulish;
}
.slider {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 500px; 
    font-size: 45px; 
    font-weight: bold;
    text-shadow: 0px 1px #000; 
    font-family: Mulish;
    text-align: center;
  }
.sample-product-subtitle{
    font-size: 20px !important; 
    text-shadow: 0px 0px #f5f9f711; 
    text-align: center;
    width: 50%;
    color: #fff;
    font-weight: 300 !important;
  }
.action-botton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.action-botton2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.content-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px;
    font-family: Mulish;
    font-size:17px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    span{
        font-size:20px;
        color: #800080e6;
        font-weight: bold;
    }
  }
  .content-services{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff !important;
  }
  .content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .content-items{
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 10px;
    padding: 15px;
    text-align: center;
  
    p{
      font-family: Mulish;
      font-size:20px;
      font-weight: 700;

      .fa-handshake-o{
        font-size:50px;
        color: #800080e6;
      }
      .fa-wallet{
        font-size:50px;
        color: #800080e6;
      }
      .fa-graduation-cap{
        font-size:50px;
        color: #800080e6;
      }
      .fa-leaf{
        font-size:50px;
        color: #800080e6;
      }
    }
  
    .items-text {
      font-family: Mulish;
      font-size:14px;
      font-weight: 500;
      text-align: center;
    }
  
  }
  .about{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background: #33aacb14;
    background-size: cover;
    margin-top: 0px;
    height: auto;

    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: bold;
        text-align: justify;
    }
    p{
        font-family: Mulish;
        font-size: 13px;
    }
  }
  .team{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    
    h4{
        margin-top: 15px;
    }

    .name{
        font-family: Mulish;
        font-size: 13px;
        font-weight: bold;
    }
    i{
        margin-top: -15px;
    }
  }
  .border{
    border-left: solid 1px #ccc;
    padding: 1.5rem;
  }
  .bd{
    padding: 1.5rem;
  }
  .about-banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    width: 100%;
    padding: 0.6rem;
  }
  .img-banner{
   
    img{
        width: 90%;
    }
  }

  .contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 11px;
    background: #33aacb14;
    background-size: cover;
    margin-top: 0px;
    height: auto;

    .support-text{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        text-align: justify;
    }
    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: 500;
        text-align: justify;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 550;
        color: #3ea4c9c9;
    }
    img{
        width: 100%;
    }
  }
  .border{
    border-left: solid 1px #ccc;
    padding: 1.5rem;
  }
  .bd{
    padding: 1.5rem;
  }
  .contact-banner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    font-family: Mulish;
    padding: 1.6rem;

    .form-control{
        font-size: 12px;
        font-family: Mulish;
    }
    .input-contact{
        margin-top: 4px;
        color: #fff;
    }
  }


  .text-banner{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Mulish;
    width: 50%;
    p{
        text-align: justify;
    }

    .our-vision{
        margin: 4px;
        font-family: Mulish;
        font-size: 13px;

        .company{
            font-weight: bold;
        }
        .mission{
            font-weight: bold;
        }
        .vision{
            font-weight: bold;
        }
        .impact{
            font-weight: bold;
        }
    }
  }


   .content-footer1 {
    display: flex;
    flex-direction: column;
    color: #fff;
    bottom: 0;
    background: #800080e6; 
  }

  .content-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 2.7rem;
    padding-left: 5%;
    padding-right: 5%;
  }
  .footer-img{
    display: flex;
    flex-direction: column;
    text-align: left
  }
  .content-footer-text{
    font-family: Mulish;
    font-size:12px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  
  .contact-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img{ width:20% }

    p{
        font-family: Mulish;
        font-size:12px;    
    }
    a{
        text-decoration: none;
        color: #fff;
    }
  }
  .contact-title{
    font-size: 18px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .services-title{
    font-size: 16px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .copyright{
    font-family: Mulish;
    font-size:13px;
    font-weight: 400;
    text-align: left;
    padding-left: 5%;
  }
  
  .input-control {
    height: 30px;
    font-size: 12px;
    font-family: Mulish;
}
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #37ABC8;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}
.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #FFF;
    }

    display: none;
}
.top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    display: block;
    z-index: 9999;
    background: #800080;
    color: #fff;
    border-radius: 45px;
    padding: 10px;
    transition: visibility 0.5s, opacity 0.5s;

    a{
        color: #fff;
    }
}
.hidden {
    visibility: hidden;
    opacity: 0;
}

.contact-support{
    position: fixed;
    bottom: 20px;
    right: 80px;
    z-index: 9999;
    background: #ccc;
    color: #fff;
    border-radius: 12px;
    transition: visibility 0.5s, opacity 0.5s;

    img{
        width: 60px;
    }
}
@media (min-width:320px) and (max-width: 720px) {

.top-header{
    font-size: 10px;
}
.core-values{
    display: flex;
    flex-direction: column;

    h3{
        font-family: Mulish;
        font-size: 15px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
        margin-top: 10px;
    }
    span{
        font-family: Mulish;
        font-size: 11px;
        font-weight: 200;
        text-align: center;
    }
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu-bar {
    display: flex; 
    flex-direction: column; 
    height:70px;
}

.menu-bar1{
    display:block;
    .fa-bars{
        font-size: 22px;
        cursor: pointer;
    }
 }
 .menu-bar1:hover{
    
        .submenu-bar1{
            display: flex;
            flex-direction: column;
            right: 0;
            font-family: Mulish;
            font-size: 13px;
            border-right: solid 1px rgba(0, 0, 0, 0.3);;
            background: #800080;
            position: fixed;
            z-index: 9998;
            //border-top-right-radius: 4px;
            //border-bottom-right-radius: 4px;
                        
            a{
                text-decoration: none;
                color: #fff;
            }
        }
        .sub-menu1{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            border-left: solid 1px #ccc;
            border-bottom: solid 1px #ccc;
            text-transform: capitalize;
            cursor: pointer;
            padding: 0.8rem;
            width: 100%;
        }
 }
 .sub-menu1:hover{
    background: #fff;
    color: #800080 !important;
 }

.guarantee{
    div{
        font-family: Mulish;
        font-size: 14px;
        font-weight: 450;
        color: #00000096;
        text-align: center;
    }
}

.bg-task{
    display: flex;
    flex-direction: column;
    height: auto;
    h2{
        font-size: 30px; 
        font-weight: 600;
        font-family: Mulish;

    }
    p{
        font-size: 12px; 
        font-weight: 400;
        font-family: Mulish;

    }
    img{
        width: 90% !important;
    }
    .btn{
        margin-bottom: 10px;
    }
    .task-img{
        display: none;
    }
}
.bg-style{
    display:flex;
    flex-direction: column;
    background-image: linear-gradient(-90deg, #80008014, #8000806b);

    img{
        width: 100%;
    }
    .platform{
        display:flex;
        flex-direction: column;
        width: 100%;
        padding: 0.6rem;

        h2{
            font-size: 30px; 
            font-weight: 100;
            font-family: Mulish;
        }
        span{
            font-size: 14px; 
            font-weight: 350;
            font-family: Mulish;
        }
    }
}

#dailytask{
    display: flex;
    flex-direction: column;  
    justify-content: center; 
    align-content: center;
    width: 100%;

    h2{
        font-family: Mulish;
        margin-top: -40px;
        z-index: 9666;
        color: #000000c0;
    }

    p{
        font-family: Mulish;
        font-size: 12px;
    }
    img{
        width: 120%;
    }
}

#dailytask .d-flex .flex-column .pt-0 .mt-0 {
    display: flex;
    flex-direction: column;
  
}


   
    .sidebar {
        width: auto;
        height: auto;
        position: fixed;
        z-index: 9998;
        transition: opacity .4s ease;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0px;
        margin-left: 10px;

        a{
            color: #37ABC8;
        }
        .close{
            margin-left: 20px;
        }
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}

   .logo{
     img{ width: 150%; }
    }
    .fa-bars {
        display: block;
        font-size: 30px;
        cursor: pointer;
        color: #800080e6;
    }
    .menu {
        display: none;
    }
    .banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 9.5rem;
        bottom: 0;
        font-family: Mulish;
        font-size: 18px;
        background:#800080e6;
        padding-top: 30px;
        overflow: hidden;
        position: relative;
        backdrop-filter: blur(16px);
        background: url(../assets/bg.jpg) no-repeat fixed center;
        background-size: cover;
        
        h1{
            font-family: Mulish;
            font-weight: 700;
            font-size: 30px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px #000; 
        }
        h4{
            font-family: Mulish;
            font-weight: 400;
            font-size: 15px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px #000; 
        }
        .btn-style{
            font-family: Mulish;
            text-align: center;
        }
        .img-style{
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                text-align: center;
                width: 250px;
            }
        }
    }
    .action-botton{
        margin-top: 50px;
    }
    .action-botton2{
        margin-top: 50px;
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
      }
      .content-items{
        box-sizing: border-box;
        width: 100%;
      }
      
      .about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        margin-bottom: 50px;
        box-shadow: -1px -1px 4px #ccc;
        height: auto;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: bold;
            text-align: justify;
        }
        p{
            font-family: Mulish;
            font-size: 13px;
        }
      }
      .team{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        
        h4{
            margin-top: 15px;
        }
    
        .name{
            font-family: Mulish;
            font-size: 13px;
            font-weight: bold;
        }
        i{
            margin-top: -15px;
        }
      }
      .border{
        border-left: solid 1px #ccc;
        padding: 1.5rem;
      }
      .bd{
        padding: 1.5rem;
      }
      .about-banner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        width: 100%;
        padding: 0.6rem;
      }
      .img-banner{
       
        img{
            width: 100%;
        }
      }

      .contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 11px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        height: auto;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: 500;
            text-align: justify;
        }
        span{
            font-family: Mulish;
            font-size: 13px;
            font-weight: 800;
        }
        img{
            width: 100%;
        }
      }

      .content-footer {
        display: flex;
        flex-direction: column;
        padding-top: 2.7rem;
        bottom: 0;   
        background: #8000802b; 
        padding-left: 5%;
        padding-right: 5%;
      }
      .content-footer-text{
        font-family: Mulish;
        font-size:13px;
        font-weight: 500;
        text-align: center;
        word-wrap: break-word;
        width: 28em; 
        color: #000;
      }
      .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
      
        p{
          font-size: 20px; 
        }
      }

    
      .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 241, 241, 0.5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #37ABC8;
            background: #f4f7f5;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .contact-info{
        display: flex;
        flex-direction: column;
    
        img{ width:20% }
    
        p{
            font-family: Mulish;
            font-size:13px;    
        }
      }
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: none;
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: block;
    }

}
</style>